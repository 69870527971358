
.riassunto[data-v-2eb0de87] {
  text-align: center;
}
.trendPanel[data-v-2eb0de87] {
  width: 70%;
}


.is-divider[data-v-e6c7105a] {
  margin: 1em;
}
.comeStoADS[data-v-e6c7105a] {
  text-align: center;
  height: 90px;
  margin-bottom: 20px;
}


/*# sourceMappingURL=chunk-335c76c4.e41c803d.css.map*/